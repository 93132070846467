import React, { useCallback } from 'react';
import facebook from 'static/assets/svg/social/facebook.svg';
import instagram from 'static/assets/svg/social/instagram.svg';
import telegram from 'static/assets/svg/social/telegram.svg';
import twitter from 'static/assets/svg/social/twitter.svg';
import vk from 'static/assets/svg/social/vk.svg';
import youtube from 'static/assets/svg/social/youtube.svg';

const iconMap = {
  facebook,
  instagram,
  telegram,
  twitter,
  youtube,
  vk,
};

const SocialLink = ({ socialLink, isCordova }) => {
  const { key, text, link } = socialLink;

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      event.preventDefault();
      const anchor = event.currentTarget;
      const href = anchor.getAttribute('href');
      window.open(href, '_system');
      return false;
    },
    [isCordova],
  );

  return (
    <a
      className='social-link'
      href={link}
      target='_blank'
      rel='noreferrer'
      onClick={handleAnchorClick}
    >
      <img src={iconMap[key] || null} alt={text} />
    </a>
  );
};

export default SocialLink;
