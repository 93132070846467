import React from 'react';
import moment from 'moment/moment';
import { LocalConfig } from '../../../../core/utils';
import ResponsibleGamblingPolicyLink from '../../ResponsibleGamblingPolicyLink/ResponsibleGamblingPolicyLink';
import TermsConditionsLink from '../../TermsConditionsLink/TermsConditionsLink';
import StaticBlock from '../../StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../../core/constants';
import AppLinks from '../AppLinks/AppLinks';
import FooterSocial from '../FooterSocial/FooterSocial';

const MobileFooter = () => {
  const copyrightText = React.useMemo(
    () => `© ${LocalConfig.get('THEME_NAME')} ${moment().get('year')}`,
    [],
  );

  return (
    <footer className='footer footer--mobile'>
      <div className='footer__container'>
        <div className='footer-block footer-block--navigation footer-block--mobile'>
          <ResponsibleGamblingPolicyLink className='nav-links__link nav-links__link--internal' />
          <TermsConditionsLink className='nav-links__link nav-links__link--internal' />
        </div>

        <AppLinks />

        <FooterSocial />

        <div className='footer-block footer-block--csm-content footer-block--mobile'>
          <StaticBlock options={{ source: cmsStaticBlock.FooterMobile }} />
        </div>

        <div className='footer-block footer-block--mobile footer-block--copyrights'>
          <div className='copyright-text'>
            {copyrightText}
            <span className='app-version-number'>
              &nbsp; build: &nbsp;
              {CORDOVA ? APP_VERSION : VERSION}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MobileFooter;
