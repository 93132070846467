import React from 'react';
import { map } from 'lodash';
import { SiteConfigManager, useSettings } from '@apollo/core';
import SocialLink from './SocialLink';

const FooterSocial = () => {
  const { isCordova } = useSettings();
  const socialLinks = SiteConfigManager.getConfig('config.config.social_links', []);

  return (
    <div className='social'>
      <div className='social-links'>
        {
          map(socialLinks, (socialLink) => (
            <SocialLink key={socialLink.key} socialLink={socialLink} isCordova={isCordova} />
          ))
        }
      </div>
    </div>
  );
};

export default FooterSocial;
